import React, { Component } from 'react';
import logo from './logo_text_white.svg';
import './App.css';

class App extends Component {
componentDidMount() {
  if (['/','/register', '/registration', '/login'].includes(window.location.pathname)) {
    console.log('trigger')
    window.location.replace('https://trade.carcollect.com');
    }
  }
  
 render() {
  return (
      <div className="App">
        <header className="App-header">
          <img 
            src={logo} 
            className="App-logo" 
            alt="logo" 
          />
          
          <p>
            CarCollect landing page
          </p>
        </header>
      </div>
    );
  }
}

export default App;
